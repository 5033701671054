import PageHeader from '@/components/PageHeader.vue'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
const Index = {
  name: 'inventoryDetails',
  components: {
    PageHeader,
    Adsearch
  },
  data() {
    return {
      status: null,
      info: {},
      selectsArr: []
    }
  },
  mixins: [tableMixin],
  mounted() {
    this.status = this.$route.params.status
    this.GetStockCorrectInfoByIDX(this.$route.params.idx)
  },
  methods: {
    selectInit(row, index) {
      if (row.isCorrected === 1) {
        return false
      }
      return true
    },
    // 勾选表格某项
    setTableRow(row) {
    // 根据表格某项的校正值做是否勾选 scope.row.isCorrected === 1
      if (row.isCorrected === 1) {
        // console.log('以判断')
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      }
    },
    selects(a) {
      console.log(a)
      this.selectsArr = a
    },
    // 获取详情数据
    async GetStockCorrectInfoByIDX(IDX) {
      const result = await this.$api.GetStockCorrectInfoByIDX({ IDX })
      this.info = result.Message
      console.log(this.info.Details)
      // this.info.Details.map(item => {
      //   // this.selectInit(item)
      //   // this.setTableRow(item)
      //   // this.$set(item, 'CorrectingNum', item.StockNum)
      // })
      console.log(result)
    },
    // 批量校正
    plStockCorrecting() {
      const arr = this.selectsArr.filter(item => item.isCorrected !== 1)
      if (arr.length <= 0) return this.$message.warning('请选择你需要校正的产品')
      const data = this.setData(arr)
      this.StockCorrecting(data, true)
    },
    // 盘点修正
    StockCorrecting(row, flag) {
      let data = {}
      if (!flag) { data = this.setData([row]) } // 单个
      if (flag) { data = row } // 批量
      this.$api.StockCorrecting(data).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)

        this.$message.success('校正成功')
        this.GetStockCorrectInfoByIDX(this.$route.params.idx)
      })
    },
    // 处理校正数据
    setData(details) {
      const obj = {
        StockCorrectID: this.$route.params.idx, // 盘点单id
        Details: []
      }
      obj.Details = details.filter(item => item.isCorrected !== 1)
      return obj
    },
    // 导入盘点
    CorrectDetails_ImportUpload() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    getFile() {
      const _this = this
      const inputFile = this.$refs.filElem.files[0]
      if (inputFile) {
        const fileName = inputFile.name.split('.')[inputFile.name.split('.').length - 1]
        if (fileName !== 'xlsx' && fileName !== 'xls') return _this.$message.warning('请上传excel表格')
        const reader = new FileReader()
        reader.readAsDataURL(inputFile)
        reader.onload = function(e) {
          console.log(this.result)
          _this.$api.CorrectDetails_ImportUpload({
            FileData: this.result,
            FileType: fileName,
            IDX: _this.$route.params.idx
          }).then(res => {
            console.log(res)
            _this.$refs.filElem.value = null
            if (res.RetCode === '0' && Array.isArray(res.Message)) {
              _this.setDataImportUpload(res.Message)
              return _this.$message.success('导入成功')
            }
            return _this.$message.error('错误：' + res.RetMsg)
          })
        }
      }
    },
    // 处理导入数据
    setDataImportUpload(list) {
      const arr = [] // 需要追加
      // 如果IDX相同并且校正标识不为1  替换
      list.map(item1 => {
        if (this.info.Details.findIndex(item => item.IDX === item1.IDX && item.isCorrected !== 1) >= 0) {
          this.info.Details.splice(this.info.Details[this.info.Details.findIndex(item => item.IDX === item1.IDX && item.isCorrected !== 1)], 1, item1)
        }
        if (this.info.Details.filter(item => item.IDX === item1.IDX).length <= 0) {
          arr.push(item1)
        }
      })
      this.info.Details = this.info.Details.concat(arr)
    },
    // 导出盘点
    CorrectDetails_Export() {
      const obj = { StockCorrectID: this.$route.params.idx, DetailsIDXs: '' }
      this.selectsArr.map((item, index) => {
        if (index !== this.selectsArr.length - 1) {
          return obj.DetailsIDXs += item.IDX + ','
        }
        return obj.DetailsIDXs += item.IDX
      })
      this.$refs.ExportLoading.open()
      this.$api.CorrectDetails_Export(obj).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('盘点清单')
        this.$refs.ExportLoading.success(res.Message, name)
        return this.$minCommon.downloadFile(res.Message, name)
      })
    },
    // 完成盘点
    submitData() {
      const obj = {
        IDX: this.$route.params.idx,
        Status: 3
      }
      this.$api.UpdateStockCorrectStatus(obj).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)

        this.$router.push({
          name: 'inventoryCorrection',
          params: { isCache: 6 }
        })
        return this.$message.success('盘点成功')
      }).catch(err => {
        return this.$message.error('错误：' + err)
      })
    },
    // 修改盈亏数量
    setNum(e, index) {
      this.$set(this.info.Details[index], 'yknum', e * 1 - this.info.Details[index].StockNum * 1)
    },
    async getTableDataAsync(page, pageSize) {
      // api/QueryStockMaterialList
      // this.formSearch.PageIndex = page || 1
      // this.formSearch.PageSize = this.tablePageSize
      // console.log(' this.formSearch', this.formSearch)
      // const response = await this.$api.QueryStockMaterialList(this.formSearch)
      // console.log('response', response)
      // if (response.RetCode !== '0') return this.$message.error('错误：' + response.Message)
      // this.tableData = response.Message
      // this.tableTotalSize = response.Total
      return { Message: [], Total: 0 }
    }
  }
}
export default Index
