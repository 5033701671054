<template>
  <div class="inventory-details">
    <!-- 已完成  start-->
    <div v-if="status === 3 ">
      <PageHeader title="盘点详情" />
      <div class="flex j-between a-center p-lr-20 m-tb-10">
        <div>
          盘点名称：{{ info.StockCorrectName }}
          盘点时间：{{ info.LunchTime }}
          盘点人：{{ info.LunchUserName }}
        </div>
        <el-button size="mini" type="primary" round @click="() => $router.back(-1)">返回</el-button>
      </div>
      <div class="p-lr-20">
        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          class="multipleTable"
          :data="info.Details"
          tooltip-effect="dark"
          show-header
          height="calc(100vh - 380px)"
          row-key="IDX"
          border
          :fit="true"
        >
          <!--    @select-all="selectAll"
        @select="select" -->
          <!--          <el-table-column fixed="left" type="selection" width="40" />-->
          <el-table-column label="物料编号" prop="InvCode" show-overflow-tooltip />
          <el-table-column label="物料名称" prop="InvName" show-overflow-tooltip />
          <el-table-column label="品牌" prop="BrandName" show-overflow-tooltip />
          <el-table-column label="物料类型" prop="MaterialSubTypeName" show-overflow-tooltip />
          <el-table-column label="规格" prop="Specification" show-overflow-tooltip />
          <el-table-column label="单位" prop="StockUnitN" show-overflow-tooltip />
          <el-table-column label="批号/序列号" prop="LotNum" show-overflow-tooltip />
          <el-table-column label="效期" prop="ExpiredDate" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
            </template>
          </el-table-column>

          <el-table-column label="系统库存数量" prop="StockNum" show-overflow-tooltip />
          <el-table-column label="盘点数量" prop="CorrectingNum" show-overflow-tooltip />
          <el-table-column label="盈亏数量" prop="yknum" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.CorrectingNum - scope.row.StockNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="校正数量" prop="CorrectedNum" show-overflow-tooltip />
          <el-table-column label="校正人" prop="CorrectUserName" show-overflow-tooltip />
          <el-table-column label="UDI码" prop="DINum" show-overflow-tooltip />
          <el-table-column label="盘点仓库" prop="StockName" show-overflow-tooltip />
          <el-table-column label="备注" prop="Remark" show-overflow-tooltip />
        </el-table>
        <!-- 备注 -->
        <div class="flex a-center m-tb-20" style="width: 50%">
          <div style="width:100px"> 盘点备注：</div> <el-input v-model="info.Remark" size="mini" />
        </div>
        <div class="pagination-container">
          <el-pagination
            :ref="paginationName"
            :total="tableTotalSize"
            :pager-count="pagerCount"
            :page-size="tablePageSize"
            :background="tableBackground"
            :current-page.sync="currentPage"
            layout="prev, pager, next, jumper"
            @current-change="handleCurrentChange($event)"
          />
        </div>
      </div>
    </div>
    <!-- 已完成  end-->
    <!-- 未完成 start -->
    <div v-if="status=== 1 || status=== 2">
      <PageHeader title="盘点详情" />
      <div class="flex j-between a-center m-tb-10">
        <div class="flex j-start a-center p-lr-20 ">
          盘点名称：{{ info.StockCorrectName }}
          盘点人：{{ info.CreateUserName }}
        </div>
        <div class="flex j-end a-center p-lr-20 input_file">
          <el-button size="mini" type="primary" round @click="plStockCorrecting">批量库存校正</el-button>
          <el-button size="mini" type="primary" round @click="CorrectDetails_Export">导出盘点清单</el-button>
          <el-button size="mini" type="primary" round @click="CorrectDetails_ImportUpload">导入盘点结果</el-button>
          <input ref="filElem" type="file" class="upload-file" @change="getFile">
          <div class="m-right-10" />
          <el-button size="mini" type="primary" round @click="submitData">完成盘点</el-button>
        </div>
      </div>

      <div class="p-lr-20">
        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          class="multipleTable"
          :data="info.Details"
          tooltip-effect="dark"
          show-header
          :max-height="$minCommon.SetTableHeight(340)"
          row-key="IDX"
          border
          :fit="true"
          @selection-change="selects"
        >
          <!--    @select-all="selectAll"
        @select="select"  :selectable="selectInit"-->
          <el-table-column fixed="left" type="selection" width="40" />
          <el-table-column label="物料编号" prop="InvCode" show-overflow-tooltip />
          <el-table-column label="物料名称" prop="InvName" show-overflow-tooltip />
          <el-table-column label="品牌" prop="BrandName" show-overflow-tooltip />
          <el-table-column label="物料类型" prop="MaterialSubTypeName" show-overflow-tooltip />
          <el-table-column label="规格" prop="Specification" show-overflow-tooltip />
          <el-table-column label="单位" prop="StockUnitN" show-overflow-tooltip />
          <el-table-column label="批号/序列号" prop="LotNum" show-overflow-tooltip />
          <el-table-column label="效期" prop="ExpiredDate" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
            </template>
          </el-table-column>
          <el-table-column label="仓位" prop="StockCode" show-overflow-tooltip />
          <el-table-column label="系统库存数量" prop="StockNum" show-overflow-tooltip />
          <el-table-column label="盘点数量" prop="CorrectingNum" show-overflow-tooltip>
            <template slot-scope="scope">
              <!--              @change="setNum($event,scope.$index )"-->
              <el-input v-model="scope.row.CorrectingNum" :disabled="scope.row.isCorrected === 1 ? true : false" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="盈亏数量" prop="yknum" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.CorrectingNum - scope.row.StockNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="库存校正" prop="" show-overflow-tooltip align="center" width="100px">
            <template slot-scope="scope">
              <el-button size="mini" :type="scope.row.isCorrected === 1 ? 'danger': 'primary'" :plain="scope.row.isCorrected === 1 " :disabled="scope.row.isCorrected === 1 ? true : false" @click="StockCorrecting(scope.row)">
                {{ scope.row.isCorrected === 1 ? '已校正' : '校正' }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="校正数量" prop="CorrectedNum" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.CorrectedNum }}
            </template>
          </el-table-column>
          <el-table-column label="UDI码" prop="DINum" show-overflow-tooltip />
          <el-table-column label="盘点仓库" prop="StockName" show-overflow-tooltip />
        </el-table>
        <!-- 备注 -->
        <div class="flex a-center m-tb-20" style="width: 50%">
          <div style="width:100px"> 盘点备注：</div> <el-input v-model="info.Remark" size="mini" />
        </div>
        <div class="pagination-container">
          <el-pagination
            :ref="paginationName"
            :total="tableTotalSize"
            :pager-count="pagerCount"
            :page-size="tablePageSize"
            :background="tableBackground"
            :current-page.sync="currentPage"
            layout="prev, pager, next, jumper"
            @current-change="handleCurrentChange($event)"
          />
        </div>
      </div>
    </div>
    <!-- 未完成 end-->
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/management/inventoryDetails'
export default Index
</script>
